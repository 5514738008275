export default {
    "APP_ORDERS_VIEW": "",
    "APP_PRODUCTS_VIEW": "",
    "APP_CATALOG_VIEW": "",
    "APP_PRDT_DTLS_VIEW": "",
    "APP_CTLG_PRDT_DTLS_VIEW": "",
    "APP_COMMERCE_VIEW": "COMMERCEUSER_VIEW",
    "MERCHANDISING_ADMIN": "MERCHANDISING_ADMIN",
    "PREORDER_APP_VIEW": "PREORDER_APP_VIEW",
    "APP_INV_CNFG_UPDT": "COMMON_ADMIN",
} as any