import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      (_ctx.job.statusId === 'SERVICE_DRAFT')
        ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                button: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.schdlInEvry15Mins()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.timerOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("Schedule in every 15 minutes")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                lines: "none",
                button: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.runNow()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.flashOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("Run now")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                lines: "none",
                button: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openJobHistoryModal()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.timeOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("History")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                lines: "none",
                button: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmJobCancellation()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.closeOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("Cancel job")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}